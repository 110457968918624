<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  import template from 'common/src/libs/template.js'
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        template: template
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      layoutData: {},
    },
    computed: {
      groupId() {
        return this.layoutData.groupId
      },
    },
    watch: {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        }
      })
      this.updateUrls()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      updateUrls() {
        var urls = {
          publicTags: template`/Api/Tags/All`,
          privateTags: template`/Api/GroupAdmin/Tags/${this.groupId}/All`,
          hotspotIcons: template`/Api/GroupAdmin/HotspotIcons/${this.groupId}`,
          fileLib: template`/Api/GroupAdmin/Libs/${this.groupId}`,
          filesUploadUrl: template`/Api/GroupAdmin/Libs/${this.groupId}/Upload`,
          panosUploadUrl: template`/Api/GroupAdmin/Panos/${this.groupId}/Upload`,
          fileUrl: template`/Api/GroupAdmin/Files/${this.groupId}`,
          panoUrl: template`/Api/GroupAdmin/Panos/${this.groupId}`,
          libPanosUrl: window.global.ENABLE_ELASTICSEARCH ? template`/Api/ES/GroupAdmin/Panos/${this.groupId}` : template`/Api/GroupAdmin/Panos/${this.groupId}`,
          libToursUrl: window.global.ENABLE_ELASTICSEARCH ? template`/Api/ES/GroupAdmin/Tours/${this.groupId}` : template`/Api/GroupAdmin/Tours/${this.groupId}`,
          panoLogo: template`/Api/GroupAdmin/Panos/${this.groupId}/Logo/${'panoId'}`,
          tourLogo: template`/Api/GroupAdmin/Tours/${this.groupId}/Logo/${'tourId'}`,
          audioLib: template`/Api/GroupAdmin/Audios/${this.groupId}`,
          weatherTypes: template`/Api/GroupAdmin/Panos/${this.groupId}/WeatherTypes`,
          panoCover: template`/Api/GroupAdmin/Panos/${this.groupId}/Cover/${'panoId'}`,
          tourCover: template`/Api/GroupAdmin/Tours/${this.groupId}/Cover/${'tourId'}`,
          panoSave: template`/Api/GroupAdmin/Panos/${this.groupId}/${'panoId'}`,
          tourSave: template`/Api/GroupAdmin/Tours/${this.groupId}/${'tourId'}`,
          tourCreate: template`/Api/GroupAdmin/Tours/${this.groupId}`,
          categorys: template`/Api/Categorys/${'module'}`,
        }
        this.getFunc('changePublicData')({ urls })
      },
    },
  }
</script>
<style scoped>
</style>
